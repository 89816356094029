<template>
  <div id="app">
    <Menu />
    <router-view/>
  </div>
</template>



<script type="text/javascript">
  import Menu from '@/components/Menu.vue'

  export default{
    components:{
      Menu
    }
  }

</script>

<style>
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
