<template>
  <div class="flex flex-col flex-wrap md:flex-row w-full overflow-hidden">
    <div class="relative z-10 p-4 sm:pb-16 md:max-w-4xl md:w-full md:pb-28 mx-auto">
      <div class=" bg-white p-4 mt-6 border-solid border-2 rounded-xl">
      
        <!-- Begin Mailchimp Signup Form -->
        <link href="//cdn-images.mailchimp.com/embedcode/classic-10_7.css" rel="stylesheet" type="text/css">

          <div id="mc_embed_signup">
          <form action="https://amanda-warner.us17.list-manage.com/subscribe/post?u=3bb52b1310b09e4c92cc2c55d&amp;id=a663a1767f" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate>
            <div id="mc_embed_signup_scroll">
            <h2>Email</h2>
            <p>webcontact@amanda-warner.com</p>
            <h2>Subscribe</h2>
            <p>Would you like to know about future games and similar projects? Sign up for my mailing list. I won't use your email address for any other purpose, and I won't contact you very often. Pinky promise!</p>
          <div class="indicates-required"><span class="asterisk">*</span> indicates required</div>
          <div class="mc-field-group">
            <label for="mce-EMAIL">Email Address  <span class="asterisk">*</span>
          </label>
            <input type="email" value="" name="EMAIL" class="required email" id="mce-EMAIL">
          </div>
           <div id="mergeRow-gdpr" class="mergeRow gdpr-mergeRow content__gdprBlock mc-field-group">
            <div class="content__gdpr">
                <label class="font-bold">Marketing Permissions</label>
                <p class="pb-2">Amanda Warner may contact me by:</p>
                <fieldset class="mc_fieldset gdprRequired mc-field-group" name="interestgroup_field">
                  <label for="gdpr_67905"><input type="checkbox" id="gdpr_67905" name="gdpr[67905]" value="Y" style="display: inline; width: auto"><span class="ml-2">Email</span></label>
                </fieldset>
                  <p>You can unsubscribe at any time by clicking the link in the footer of our emails.</p>
            </div>
            <div class="content__gdprLegal">
                <p>We use Mailchimp as our marketing platform. By clicking below to subscribe, you acknowledge that your information will be transferred to Mailchimp for processing. <a href="https://mailchimp.com/legal/" target="_blank">Learn more about Mailchimp's privacy practices here.</a></p>
            </div>
        </div>
          <div id="mce-responses" class="clear">
            <div class="response" id="mce-error-response" style="display:none"></div>
            <div class="response" id="mce-success-response" style="display:none"></div>
          </div>    <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
            <div style="position: absolute; left: -5000px;" aria-hidden="true"><input type="text" name="b_3bb52b1310b09e4c92cc2c55d_a663a1767f" tabindex="-1" value=""></div>
            <div class="clear">
              <input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" class="button text-white hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-3x font-medium"></div>
            </div>
          </form>
          </div>
              <!--End mc_embed_signup-->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'About',

}
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  button, .button {
    background-color: #00b5ad !important;
    font-weight: bold !important;
    cursor: pointer;
  }
</style>
