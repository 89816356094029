<template>
    <!-- This example requires Tailwind CSS v2.0+ -->
    <nav class="bg-gray-900">
    <div class="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
        <div class="relative flex items-center justify-between h-16">
            <div class="flex-1 flex items-center justify-center sm:items-stretch sm:justify-end">
                <div class="flex space-x-4">
                    <router-link :to="{ name: 'home' }" exact class="text-white hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium">Home</router-link>
                    <router-link :to="{ name: 'projects' }" class="text-white hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium">Projects</router-link>
                    <router-link :to="{ name: 'contact' }" class="text-white hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium">Contact</router-link>
               </div>
            </div>
        </div>
    </div>
    </nav>
</template>

<script>
export default {
  name: 'Menu',
    
}
</script>


<style scoped>

    nav .router-link-active,
    nav .router-link-exact-active {
        background-color: #00b5ad;
        cursor: pointer;
    }

</style>
