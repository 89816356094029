<template>
  <div>
    <Projects />
  </div>
</template>

<script>
import Projects from '@/components/Projects.vue'


export default {
  name: 'projects',
  components: {
    Projects
  }
}
</script>