<template>
  <div>
    <About />
  </div>
</template>

<script>
import About from '@/components/About.vue'


export default {
  name: 'home',
  components: {
    About
  }
}
</script>
