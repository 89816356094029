<template>
  <div class="flex flex-col flex-wrap md:flex-row w-full overflow-hidden">
    <div class="relative z-10 p-4 md:max-w-4xl md:w-full mx-auto">
      <div class=" bg-white p-4 text-center rounded-xl border-gray-200 border-solid border-2">
      <img src="../assets/images/consulting.png" alt="A lightbulb, flow chart, and laptop" class="ui rounded centered image" />
        <div class="p-6">
          <h1>Amanda Warner is ...</h1>
          <p>a designer and developer who creates thought-provoking games, educational simulations, and meaningful interactions.</p>
          <p>interested in projects  at the intersection of good design and positive impact.</p>
          <br/>
          <img class="object-center" src="../assets/images/intersection.png" alt="Intersection" />
          <br/>
          <p>Her past projects include collaborations with the WHO, Bill & Melinda Gates Foundation, UNICEF, Humanitarian Leadership Academy, and Transparency International.</p>
          <div class="flex w-full justify-center mt-8">
            <router-link :to="{ name: 'projects' }" exact class="text-white hover:bg-gray-700 hover:text-white px-5 py-3 font-medium rounded-md text-3x self-center button">View Projects</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'About',

}
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  button, .button {
    background-color: #00b5ad !important;
    cursor: pointer;
  }


.stackable.three.column.grid {
  text-align: center;
}

img {
  display:unset;
}

.tagline {
  color: #333333;
  font-size: 56px;
  line-height: 80px;
  text-align: center;
}

.color {
  color: #00b5ad;
}

h1 {
  font-size: 40px;
  line-height: 50px;
  
}

h1.main {
  font-size: 40px;
  color: #333333;
}

h2 {
  font-size: 30px;
  line-height: 40px;
  color: #333333;
}

.sample {
  text-align: center;
}

.subheader {

  font-weight: bold;
}

div.ui.grid.services {
  margin-left: 1px;
  margin-right: 1px;
}

</style>

